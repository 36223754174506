// JavaScript code for the custom web component
class Accordion extends HTMLElement {
    constructor() {
      super();
      this._header = this.querySelector('accordion-header');
      this._content = this.querySelector('accordion-content');
      this._arrow = this.querySelector('arrow');
      this.bindEvents();
    }

    bindEvents(){
      this._header = this.querySelector('accordion-header');
      this._content = this.querySelector('accordion-content');
      this._arrow = this.querySelector('arrow');
      this.querySelector('accordion-header').addEventListener('click', this.toggle.bind(this))
      console.log('bind events')
    }
  
    connectedCallback() {
      this.setAttribute('role', 'presentation');
      this._header.setAttribute('role', 'button');
      this._header.setAttribute('aria-expanded', 'false');
      this._content.setAttribute('role', 'region');
      this._content.setAttribute('aria-hidden', 'true');
    }
  
    toggle() {
      const expanded = this._header.getAttribute('aria-expanded') === 'true';
      console.log(expanded)
      const accordionGroup = this.closest('accordion-group');
      if (accordionGroup) {
        accordionGroup.closeAllExcept(this);
      }
      if(expanded) {
        const listHeight = this._content.firstElementChild.clientHeight;
        this._content.classList.remove(`max-h-[${listHeight}px]`);
        this._content.classList.add('max-h-0');
        this._arrow.classList.remove('rotate-180');
      } else {
        const listHeight = this._content.firstElementChild.clientHeight;
        this._content.classList.add(`max-h-[${listHeight}px]`);
        this._content.classList.remove('max-h-0');
        this._arrow.classList.add('rotate-180');
      }
      this._header.setAttribute('aria-expanded', !expanded);
      this._content.setAttribute('aria-hidden', expanded);

      //add classes for content to display
    }
  
    open() {
      if (this._header.getAttribute('aria-expanded') !== 'true') {
        this.toggle();
      }
    }
  
    close() {
      if (this._header.getAttribute('aria-expanded') === 'true') {
        this.toggle();
      }
    }
  }
  
  class AccordionGroup extends HTMLElement {
    constructor() {
      super();
    }
  
    connectedCallback() {
      this.setAttribute('role', 'tablist');
    }
  
    closeAllExcept(exceptAccordion) {
      const accordions = Array.from(this.querySelectorAll('drop-down'));
      accordions.forEach((accordion) => {
        if (accordion !== exceptAccordion) {
          accordion.close();
        }
      });
    }
  }
  
  class AccordionHeader extends HTMLElement {
    constructor() {
      super();
      this.setAttribute('role', 'button');
      this.setAttribute('tabindex', '0');
    }
  }
  
  class AccordionContent extends HTMLElement {
    constructor() {
      super();
      this.setAttribute('role', 'tabpanel');
    }
  }
  
  customElements.define('drop-down', Accordion);
  customElements.define('accordion-group', AccordionGroup);
  customElements.define('accordion-header', AccordionHeader);
  customElements.define('accordion-content', AccordionContent);
  